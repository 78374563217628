import React from 'react';
import { TableProps } from '@cloudscape-design/components';
import StatusIndicator from '@cloudscape-design/components/status-indicator';
import { TFunc } from 'src/common/types';
import { TaskExecutionItem } from 'src/common/types/TaskExecutionItem';
import { eventStatusVsStatusIndicatorTypeMap } from 'src/common/utils/table';
import { EventStatus } from 'src/common/types/EventStatus';

function startTimeSortingComparator(
  firstExecution: TaskExecutionItem,
  secondExecution: TaskExecutionItem,
) {
  return (
    new Date(firstExecution.time!).getTime() -
    new Date(secondExecution.time!).getTime()
  );
}

export const columnDefinitions = (
  t: TFunc,
): TableProps.ColumnDefinition<TaskExecutionItem>[] => [
  {
    id: 'executionId',
    header: t('execution_id'),
    cell: (item: TaskExecutionItem) => item.executionId,
    sortingField: 'executionId',
  },
  {
    id: 'taskVersion',
    header: t('version_number'),
    cell: (item: TaskExecutionItem) => item.taskVersion,
    sortingField: 'taskVersion',
  },
  {
    id: 'status',
    header: t('status'),
    cell: (item: TaskExecutionItem) => (
      <StatusIndicator
        type={
          eventStatusVsStatusIndicatorTypeMap[
            item.status ?? EventStatus.NOT_STARTED
          ]
        }
      >
        {item.status ?? EventStatus.NOT_STARTED}
      </StatusIndicator>
    ),
    sortingField: 'status',
  },
  {
    id: 'time',
    header: t('start_time'),
    cell: (item: TaskExecutionItem) => item.time,
    sortingField: 'time',
    sortingComparator: startTimeSortingComparator,
  },
  {
    id: 'closeMonth',
    header: t('close_month'),
    cell: (item: TaskExecutionItem) => item.closeMonth,
    sortingField: 'closeMonth',
  },
];
